import React, { useEffect, useState } from 'react';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getButtonStyle} from '_base'
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useSocial } from 'contexts/social';
import HiddenCanvas from 'components/hiddenCanvas';

function CreatePost({post, isComment, onDone}) {
    const { member } = useAuth();
    const { dict } = useLang();
    const { topics, createTopic, savePost } = useSocial();
    const [message, setMessage] = useState('');
    const [images, setImages] = useState([]);
    const [topicId, setTopicId] = useState(0);

    useEffect(() => {
        if (post && !isComment) {
            setMessage(post.post.message);
            setImages(post.media);
            setTopicId(post.topicId);
        }
    }, [post]);
    
    useEffect(() => {
        if (isComment) {
            setTopicId(post.topicId);
        }
    }, [isComment])

    const uploadMediaHandler = (imageData) => {
        setImages(images.concat({imageData}));
    }
    const deleteMediaHandler = (imageIdx) => {
        let newImgArray = images.splice(imageIdx, 1);
        setImages(newImgArray);
    }
    const savePostHandler = () => {
        let data = {
            'memberId': member.id, 
            'topicId': topicId,
            'message': message, 
            'images': images};
        if (post && !isComment) {
            data["id"] = post.post.id;
        }
        if (isComment) {
            data["parentId"] = post.post.id;
        }
        savePost(data, () => onDone());
    }
    
    const newTopicHandler = () => {
        const newTopic = prompt('Create new topic (64 characters max)');
        if (newTopic) {
            const foundTopic = topics.find(t => t.topic.trim().toLowerCase() === newTopic.trim().toLocaleLowerCase());
            if (foundTopic) {
                setTopicId(foundTopic.id);
            } else {
                setTopicId(createTopic(newTopic));
            }
        }
    }

    return(
        <div className='create-classified'>
            <div className='back-button-container'>
                <div onClick={onDone}><FontAwesomeIcon icon={faAngleLeft}/> {dict.backToPosts}</div>
            </div>
            {!isComment &&
                <>
                <fieldset>
                    <legend>Topic</legend>
                    <span>Topic for this post: </span>
                    <select value={topicId} onChange={evt => setTopicId(evt.target.value)}>4
                        <option value='0'>-- Chose One --</option>
                        {topics.map(t => <option value={t.id}>{t.topic}</option>)}
                    </select>
                        <button className='linkButton' onClick={newTopicHandler}>Create new topic</button>
                </fieldset>
                <div className='classified-media'>
                    <label>Images</label>
                    <HiddenCanvas buttonTitle='Add image' width={400} height={400} onChange={uploadMediaHandler}/>
                    <div className='image-viewer'>
                        {images.map((image, idx) => 
                            <div key={idx} className='image-view-container'>
                                <div className='image-view-image' style={{backgroundImage:`url(${image.imageData})`}} />
                                <div className='image-view-buttons'>
                                    <button className='linkButton' onClick={() => deleteMediaHandler(idx)}>Remove</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                </>
            }
            <div className='classified-details'>
                <label>{dict.postMessage}</label>
                <textarea name='message' value={message} onChange={e => setMessage(e.target.value)} maxLength='1024' />
            </div>
            <div className='classified-save-button'>
                <button style={getButtonStyle()} onClick={savePostHandler}>{dict.save}</button>
            </div>
            
        </div>
    )
}

export default CreatePost;