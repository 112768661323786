import ProgressIndicator from 'components/progressIndicator';
import { EventProvider } from 'contexts/event';
import { EventCompletionProvider } from 'contexts/eventCompletion';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { CompleteEvent } from 'purchasing/complete';
import Payment from 'purchasing/payment';
import SpacePicker from 'purchasing/spacePicker';
import React, { useEffect, useState } from 'react';
import VendorSummary from './vendorSummary';
import { toSpliced } from '_base';
import VendorInfo from 'purchasing/vendorInfo';
import { useVendorInfo } from 'contexts/purchasing/vendorInfo';
import Agreement from 'purchasing/eventAgreement';
import { useConfirmation } from 'contexts/purchasing/confirmation';
import moment from 'moment';
import TicketPicker from 'purchasing/ticketPicker';

const vendorSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'spaces', label: 'Select Spaces', complete: false, state: 'todo'},
    {id: 'confirm', label: 'Confirm', complete: false, state: 'todo'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function EventVendor({event}) {
    const { setDescription, spaces, agreement, paid, completed } = usePurchaseOrder();
    const { email, isPiComplete } = useVendorInfo();
    const { confirmed, setConfirmed } = useConfirmation();
    const [steps, setSteps] = useState(vendorSteps);
    const [currentStep, setCurrentStep] = useState(0);
    const [hasTickets, setHasTickets] = useState(false);

    useEffect(() => {
        if (event) {
            setDescription(event.name);
            if (event.tickets.length > 0) {
                setHasTickets(event.tickets.filter(t => moment().isAfter(t.startdate) && moment().isBefore(t.enddate)).length > 0);
            }
        }
    }, [event])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [isPiComplete])

    useEffect(() => {
        if (spaces.length > 0) {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        }
    }, [spaces])

    useEffect(() => {
        if (confirmed) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
            // proceed to the next step
            setCurrentStep(currentStep+1);
        }
    }, [confirmed])

    useEffect(() => {
        if (agreement) {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: true}));
        } else {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: false}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 4, 1, {...steps[4], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 5, 1, {...steps[5], complete: true}));
        }
    }, [completed])

    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <VendorInfo event={event}/>}
                {currentStep === 1 && 
                    <EventProvider eventId={event.id}>
                        <SpacePicker eventId={event.id} />
                    </EventProvider>
                }
                {currentStep === 2 && 
                    <>
                        {hasTickets && 
                            <>
                                <p>Purchase tickets</p>
                                <TicketPicker event={event}/>
                            </>
                        }
                        <p>Or click the &quot;Continue Purchase&quot; button when you are ready to agree to the terms of the sale and complete the purchase.
                            Click &quot;Cancel&quot; on the right to cancel this purchase. Do not use the browser back button.
                        </p>
                        <div className='button-container' style={{justifyContent:'flex-start'}}>
                            <button onClick={() => setConfirmed(true)}>Continue Purchase</button>
                        </div>
                    </>
                }
                {currentStep === 3 && <Agreement event={event} />}
                {currentStep === 4 &&
                    <PaymentLoaderProvider email={email}>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                {currentStep === 5 &&
                    <EventCompletionProvider>
                        <CompleteEvent event={event} />
                    </EventCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            {event && <VendorSummary />}
        </div>
    )
}

export default EventVendor;