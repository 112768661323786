import React, {useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {getButtonStyle} from '_base'
import { useSettings } from 'contexts/settings';

function PaymentMethod({onFinished, onCancel}) {
    const { getClientSecret } = useSettings();
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [paymentMethodId, setPaymentMethodId] = useState(null);
    const stripe = useStripe();
    const elements = useElements();
    let gettingClientSecret = false;

    useEffect(() => {
        getClientSecretHandler();
    }, []);

    const getClientSecretHandler = async () => {
        if (!gettingClientSecret) {
            gettingClientSecret = true;
            try {
                await getClientSecret((secret) => setClientSecret(secret));
            } catch (e) {
                setError(e.message);
            } finally {
                gettingClientSecret = false;
            }
        }
    }
    /**
     * handle the change events in the card element 
     */
    const handleChange = async(event) => {
        // listen for changes
        setDisabled(event.empty);
        setError(event.error ? event.error.message : null);
    }
    /**
     * handle the form submission
     */
    const handleSubmit = async ev => {
        ev.preventDefault();
        if (succeeded) {
            onFinished(paymentMethodId);
            return;
        }
        setProcessing(true);

        const payload = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        if (payload.error) {
            setError(`Saving Failed: ${payload.error.message}`);
            setProcessing(false);
        } else {
            setPaymentMethodId(payload.setupIntent.payment_method);
            setError(null);
            setProcessing(false);
            setSucceeded(true);
        }
    }
	return (
        <div className='payment-form' style={{"margin":"auto"}}>
            <CardElement id='card-element' options={cardStyle} onChange={handleChange} />
            {succeeded && <div>Credit Card Saved</div>}
            <button style={getButtonStyle()} disabled={processing || disabled} onClick={handleSubmit}>
                <span>
                    {processing ? (<div>Saving...</div>) : succeeded ? "Done" : ("Save Credit Card")}
                </span>
            </button>
            <button style={getButtonStyle()} onClick={onCancel}>Cancel</button>
            {/* show any errors */}
            {error && <div role='alert'>{error}</div>}
        </div>
    )
}

export default PaymentMethod;

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    },
    disableLink: true
};