import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HiddenCanvas from 'components/hiddenCanvas';
import { useLang } from 'contexts/lang';
import React, { useEffect, useState } from 'react';
import {getButtonStyle} from '_base'
import { useMyRides } from 'contexts/myRides';

function RideCreator({ride, onDone}) {
    const { dict } = useLang();
    const { createRide, updateRide } = useMyRides();
    const [year, setYear] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [engine, setEngine] = useState('');
    const [trim, setTrim] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (ride) {
            setYear(ride.year);
            setMake(ride.make);
            setModel(ride.model);
            setEngine(ride.engine);
            setTrim(ride.trim);
            setDescription(ride.description);
            setImages(ride.images);
        }
    }, [ride])

    const uploadMediaHandler = (imageData) => {
        setImages(images.concat({imageData}));
    }
    const deleteMediaHandler = (imageIdx) => {
        let newImgArray = images.splice(imageIdx, 1);
        setImages(newImgArray);
    }

    const saveRideHandler = () => {
        if (ride) {
            const data = {...ride, year, make, model, engine, trim, description, images};
            updateRide(data);
        } else {
            const data = {year, make, model, engine, trim, description, images}
            createRide(data);
        }
        onDone();
    }

    return (
        <div className='create-classified'>
            <div className='back-button-container'>
                <div onClick={onDone}><FontAwesomeIcon icon={faAngleLeft}/> Back</div>
            </div>
            <fieldset>
                <div style={{display:'flex',flexFlow:'column'}}>
                <legend>Your ride</legend>
                <label>Year</label>
                <input type='text' value={year} onChange={evt => setYear(evt.target.value)} maxLength={4}/>
                <label>Make</label>
                <input type='text' value={make} onChange={evt => setMake(evt.target.value)} maxLength={50}/>
                <label>Model</label>
                <input type='text' value={model} onChange={evt => setModel(evt.target.value)} maxLength={50}/>
                <label>Engine/Transmission</label>
                <input type='text' value={engine} onChange={evt => setEngine(evt.target.value)} maxLength={128}/>
                <label>Trim</label>
                <input type='text' value={trim} onChange={evt => setTrim(evt.target.value)} maxLength={50}/>
                <label>Your ride's story</label>
                <textarea name='message' value={description} onChange={e => setDescription(e.target.value)} 
                    maxLength='2048' style={{height:'200px'}} />
                </div>
            </fieldset>
            <div className='classified-media'>
                <label>Images</label>
                <HiddenCanvas buttonTitle='Add image' width={400} height={400} onChange={uploadMediaHandler}/>
                <div className='image-viewer'>
                    {images.map((image, idx) => 
                        <div key={idx} className='image-view-container'>
                            <div className='image-view-image' style={{backgroundImage:`url(${image.imageData})`}} />
                            <div className='image-view-buttons'>
                                <button className='linkButton' onClick={() => deleteMediaHandler(idx)}>Remove</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='classified-save-button'>
                <button style={getButtonStyle()} onClick={saveRideHandler}>{dict.save}</button>
            </div>
            
        </div>
    )
}

export default RideCreator;