import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';

const VoterContext = React.createContext(null);

function VoterProvider({voterId, children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [voterLoading, setVoterLoading] = useState(true);
    const [voter, setVoter] = useState(null);
    const [entriesLoading, setEntriesLoading] = useState(true);
    const [entries, setEntries] = useState([]);
    const [submittingBallot, setSubmittingBallot] = useState(false);
    const [ballotStatus, setBallotStatus] = useState('');
    const [ballotStatusMessage, setBallotStatusMessage] = useState('');

    useEffect(() => {
        if (tenant) {
            loadVoter();
        }
    }, [tenant])

    const loadVoter = () => {
        setVoterLoading(true);
        runNoAuthAction('get_voter', {tenantId: tenant.id, voterId}, response => {
            if (response.voter) {
                setVoter(response.voter);
            }
            setVoterLoading(false);
        })
    }

    const getEntries = (eventId) => {
        setEntriesLoading(true);
        runNoAuthAction('get_entries', {tenantId: tenant.id, eventId: eventId}, response => {
            let _entries = [...response.entries];
            _entries.sort(function(a, b) {return a.carClass > b.carClass ? 1 : a.carClass < b.carClass ? -1 : 0;});

            setEntries(_entries);
            setEntriesLoading(false);
        })
    }

    const submitBallot = (data) => {
        setSubmittingBallot(true);
        runNoAuthAction('submit_ballot', data, response => {
            setBallotStatus(response.status);
            setBallotStatusMessage(response.statusMessage);
            setSubmittingBallot(false);
        });
    }

    const provider = {
        voterLoading, voter, 
        submittingBallot, submitBallot,
        getEntries, entriesLoading, entries,
        ballotStatus, ballotStatusMessage,
    }

    return <VoterContext.Provider value={provider}>{children}</VoterContext.Provider>
}

function useVoter() {
    const context = React.useContext(VoterContext);
    if (!context) {
        throw new Error('useVoter must be used within a VoterProvider');
    }
    return context;
}

export { VoterProvider, useVoter }