import React, { useEffect, useState } from 'react';
import Models from './models';
import { useRegistrationInfo } from 'contexts/purchasing/registrationInfo';
import { useLang } from 'contexts/lang';
import { useClasses } from 'contexts/purchasing/classes';
import { ModelsProvider } from 'contexts/models';
import { toSpliced } from '_base';

function RegistrationInfo({event}) {
    const { dict } = useLang();
    const { year, setYear,
        make, setMake, 
        model, setModel,
        trim, setTrim,
        color, setColor,
        engine, setEngine,
        carClass, setCarClass,
        values, setValues,
    } = useRegistrationInfo();
    const { classes } = useClasses();

    const updateCarClass = (evt) => {
        setCarClass(evt.target.value);
    }

    const updateOptionValue = (optionIndex, value) => {
        const valueIdx = values.findIndex(v => v.value.optionId === value.optionId && v.optionIndex === optionIndex);
        console.log('optionId:', value.optionId, ', valueIdx:', valueIdx, ', value:', value.value);
        if (valueIdx == -1) {
            setValues([...values, {optionIndex, value}]);
        } else {
            setValues(toSpliced(values, valueIdx, 1, {optionIndex, value}));
        }
    }

    return (
        <div className='registration-info'>
            <p>{dict.allFieldsRequired}</p>
            <label>Year*</label>
            <input type='text' value={year} maxLength='24' onChange={(evt) => setYear(evt.target.value)}/>
            {event.typedMakeAndModel ?
                <>
                    <label>Make*</label>
                    <input type='text' value={make} maxLength='24' onChange={(evt) => setMake(evt.target.value)} />
                    <label>Model*</label>
                    <input type='text' value={model} maxLength='64' onChange={(evt) => setModel(evt.target.value)}/>
                </>
                :
                <ModelsProvider eventId={event.id}>
                    <Models />
                </ModelsProvider>
            }
            <label>Trim</label>
            <input type='text' value={trim} maxLength='64' onChange={(evt) => setTrim(evt.target.value)} />
            <label>Color*</label>
            <input type='text' value={color} maxLength='24' onChange={(evt) => setColor(evt.target.value)} />
            <label>Engine*</label>
            <input type='text' value={engine} maxLength='10' onChange={(evt) => setEngine(evt.target.value)} />
            <div>
                <label>Class*</label>
                <select value={carClass} onChange={updateCarClass}>
                    <option value=''>-- Select --</option>
                    {
                        classes.map((c) => <option key={c.id} value={c.id}>{c.id}:{c.description}</option>)
                    }
                </select>
            </div>
            {event.registrationOptions.map((opt, optionIndex) => 
                <RegistrationOption 
                    key={`option-${optionIndex}`} 
                    option={opt} 
                    onChange={(value) => updateOptionValue(optionIndex, value)}/>)
            }
        </div>
    )
}

function RegistrationOption({option, onChange}) {
    const [value, setValue] = useState({value: '', optionId: option.id});

    useEffect(() => {
        onChange(value);
    }, [value]);

    return (
        <>
            <label>{option.label}</label>
            {option.type === 1 ?
                <input type='text' value={value.value} onChange={e => setValue({value: e.target.value, optionId: option.id})} maxLength={64} />
                : option.type === 2 ?
                <select value={value.value} onChange={e => setValue({value: e.target.value, optionId: option.id})}>
                    <option value=''>-Choose one-</option>
                    {option.choices.map(choice =>
                        <option key={choice.id} value={choice.label}>{choice.label}</option>
                    )}
                </select>
                : null
            }
        </>
    )
}

export default RegistrationInfo;